import './App.css';
import LandingPage from './LandingPage';
import { Helmet } from 'react-helmet';

function App() {
  return (
    <div className="yulu7_main_container">
      <Helmet>
        <title>YUL7</title>
        <meta
          name="description"
          content="Yul7 is dedicated to redefining the beauty industry by creating innovative tools and experiences that inspire joy and excitement even in the simplest things."
        />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <LandingPage />
    </div>
  );
}

export default App;
